import { Button, Input } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import CenteredLoading from '../components/CenteredLoading';
import { BigTitle } from '../components/Elements';
import { toast } from "react-toastify";
import config from '../config';
import { useDebouncedCallback } from 'use-debounce';

export default function VerifyEmail() {
    const [searchParams] = useSearchParams();
    const [verificationResult, setVerificationResult] = useState(null);
    const [email, setEmail] = useState("");

    useEffect(() => {
        let code = searchParams.get('code');
        if (code == null) return;

        verify(code);
    }, [searchParams])

    const resendVerification = useDebouncedCallback(() => {
        if (email === "") {
            toast.error("O email não pode ser vazio");
            return;
        }

        axios
            .post(`${config.API_URL}/users/resend-verification`, { email })
            .then(() => {
                toast.success("Verificação enviada com sucesso!");
            })
            .catch(err => {
                switch (err.response?.status) {
                    case 404:
                        toast.error("Não há nenhum utilizador registado com este email");
                        break;
                    case 429:
                        toast.error("Já foi enviado um email de verificação recentemente!");
                        break;
                    case 400:
                        toast.error("Email inválido");
                        break;
                    default:
                        toast.error("Ocorreu um erro inesperado");
                        break;
                }
            });
    }, 500, { leading: true });

    const verify = (code) => {
        axios
            .post(`${config.API_URL}/users/verify-email`, { verificationCode: code })
            .then(() => {
                setVerificationResult(true);
            })
            .catch((err) => {
                setVerificationResult(err.response?.status === 304);
            });
    };

    if (verificationResult == null) {
        return <CenteredLoading />
    }

    if (!verificationResult) {
        return <div style={{ textAlign: "center" }}>
            <BigTitle style={{ marginTop: 64 }}>
                {"A verificação falhou :("}
            </BigTitle>
            <p style={{ margin: 32 }}>É provável que o link tenha expirado.</p>
            <Input placeholder='Email' onChange={e => setEmail(e.target.value)} style={{ width: 300 }} value={email} />
            <Button type="primary" onClick={resendVerification}>
                Reenviar
            </Button>
        </div>
    }

    return <div style={{ textAlign: "center" }}>
        <BigTitle style={{ marginTop: 64 }}>
            {"Verificação concluída!"}
        </BigTitle>
        <Button type="primary" onClick={() => window.location.href = "/"}>
            Voltar ao site
        </Button>
    </div>
}
