import React from 'react'
import { HeroContainer, HeroBgImage, HeroSubtitle, HeroOverlay, HeroLogo, HeroTitle, HeroDescription, AppButtons, AppButton, BottomText } from './HeroElements'
import playStore from "../../images/google-play.png";
import appStore from "../../images/app-store.png";
import config from '../../config';
import logoWhite from "../../images/logo-white.png";

export default function Hero() {
    return (
        <HeroContainer>
            <HeroBgImage>
                <HeroOverlay>
                    <HeroLogo src={logoWhite} />
                    <HeroTitle>Culturama</HeroTitle>
                    <HeroSubtitle>Conhecer a cultura nunca foi tão emocionante</HeroSubtitle>
                    <HeroDescription>Eventos dinâmicos e competitivos onde as pessoas se podem conectar, conhecer a cultura e viver novas experiências.</HeroDescription>
                    <AppButtons>
                        <a href={config.PLAY_STORE_URL}>
                            <AppButton src={playStore} />
                        </a>
                        <a href={config.APP_STORE_URL}>
                            <AppButton src={appStore} />
                        </a>
                    </AppButtons>
                    <BottomText>
                        Para empresas (indústria, serviços, turismo, outras), entidades públicas (municípios, juntas de freguesia, universidades, politécnicos, escolas)
                        e outras organizações/associações, que pretendam promover eventos dinâmicos.<br />
                        Contacte-nos em <strong>culturama@weproductise.com</strong>.<br />
                        Mais novidades e detalhes sobre serviços a partir de 15 de outubro.
                    </BottomText>
                    <BottomText style={{ marginTop: 30 }}>
                        Powered by <a href="https://weproductise.com/">Weproductise</a>
                    </BottomText>
                </HeroOverlay>
            </HeroBgImage>
        </HeroContainer>
    )
}
