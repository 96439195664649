import { Button, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BigTitle } from "../components/Elements";
import { toast } from "react-toastify";
import config from "../config";
import { useDebouncedCallback } from "use-debounce";

export default function ResetPassword() {
	const [searchParams] = useSearchParams();
	const [resetResult, setResetResult] = useState(null);
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

	const resetPassword = useDebouncedCallback(
		() => {
			let code = searchParams.get("code");
			if (code == null || code === "") {
				setResetResult(false);
				return;
			}

			if (newPassword === "") {
				toast.error("A password não pode ser vazia");
				return;
			}

			if (newPassword !== newPasswordConfirmation) {
				toast.error("As passwords devem coincidir");
				return;
			}

			axios
				.post(`${config.API_URL}/users/reset-password`, {
					password: newPassword,
					verificationCode: code,
				})
				.then(() => {
					toast.success("Password redefinida com sucesso!");
					setNewPassword("");
					setNewPasswordConfirmation("");
				})
				.catch((err) => {
					switch (err.response?.status) {
						case 400:
							toast.error("Password muito curta");
							break;
						case 403:
						case 410:
							setResetResult(false);
							break;
						default:
							toast.error("Ocorreu um erro inesperado");
							break;
					}
				});
		},
		500,
		{ leading: true }
	);

	if (resetResult == null) {
		return (
			<div style={{ textAlign: "center" }}>
				<BigTitle style={{ marginTop: 64 }}>Redefinir Password</BigTitle>
				<Input
					placeholder="Nova Password"
					type="password"
					onChange={(e) => setNewPassword(e.target.value)}
					style={{ width: 300 }}
					value={newPassword}
				/>
				<br />
				<Input
					placeholder="Confirmar Password"
					type="password"
					onChange={(e) => setNewPasswordConfirmation(e.target.value)}
					style={{ width: 300, marginTop: 16 }}
					value={newPasswordConfirmation}
				/>
				<br />
				<Button
					type="primary"
					onClick={resetPassword}
					style={{ marginTop: 16 }}
				>
					Redefinir Password
				</Button>
			</div>
		);
	}

	if (!resetResult) {
		return (
			<div style={{ textAlign: "center" }}>
				<BigTitle style={{ marginTop: 64 }}>
					{"A alteração da palavra-passe falhou :("}
				</BigTitle>
				<p style={{ margin: 32 }}>O link de redefinição expirou.</p>
				<Button type="primary" onClick={() => (window.location.href = "/")}>
					Voltar ao site
				</Button>
			</div>
		);
	}

	return (
		<div style={{ textAlign: "center" }}>
			<BigTitle style={{ marginTop: 64 }}>
				{"Palavra-passe alterada com sucesso!"}
			</BigTitle>
			<Button type="primary" onClick={() => (window.location.href = "/")}>
				Voltar ao site
			</Button>
		</div>
	);
}
