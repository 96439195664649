import { Button, Typography } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BigTitle } from '../components/Elements';
import { toast } from "react-toastify";
import config from '../config';
import { useDebouncedCallback } from 'use-debounce';

export default function DeleteAccount() {
    const [searchParams] = useSearchParams();
    const [deleteResult, setDeleteResult] = useState(null);

    const deleteAccount = useDebouncedCallback(() => {
        let code = searchParams.get("code");
        if (code == null || code === "") {
            setDeleteResult(false);
            return;
        }

        axios
            .post(`${config.API_URL}/users/delete-account`, { verificationCode: code })
            .then(() => {
                toast.success("Conta apagada com sucesso!");
                setDeleteResult(true);
            })
            .catch(err => {
                switch (err.response?.status) {
                    case 403:
                    case 410:
                        setDeleteResult(false);
                        break;
                    default:
                        toast.error("Ocorreu um erro inesperado");
                        break;
                }
            });
    }, 500, { leading: true });

    if (deleteResult == null) {
        return <div style={{ textAlign: "center" }}>
            <BigTitle style={{ marginTop: 64 }}>
                Apagar Conta
            </BigTitle>
            <Typography>Tens a certeza que queres apagar a conta? O teu username vai continuar ocupado, mas o teu perfil deixará de ficar visível e todos os teus dados pessoais serão apagados da nossa base de dados.</Typography>
            <br />
            <Button type="primary" onClick={deleteAccount} style={{ marginTop: 16 }}>
                Apagar Conta
            </Button>
        </div>;
    }

    if (!deleteResult) {
        return <div style={{ textAlign: "center" }}>
            <BigTitle style={{ marginTop: 64 }}>
                {"A remoção da conta falhou :("}
            </BigTitle>
            <p style={{ margin: 32 }}>O link de redefinição expirou.</p>
            <Button type="primary" onClick={() => window.location.href = "/"}>
                Voltar ao site
            </Button>
        </div>
    }

    return <div style={{ textAlign: "center" }}>
        <BigTitle style={{ marginTop: 64 }}>
            {"Conta apagada com sucesso!"}
        </BigTitle>
        <Button type="primary" onClick={() => window.location.href = "/"}>
            Voltar ao site
        </Button>
    </div>
}
