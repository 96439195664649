import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import VerifyEmail from './pages/VerifyEmail';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from './pages/ResetPassword';
import DeleteAccount from './pages/DeleteAccount';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/verify-email' element={<VerifyEmail />} />
        <Route exact path='/reset-password' element={<ResetPassword />} />
        <Route exact path='/delete-account' element={<DeleteAccount />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
