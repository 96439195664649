import React from 'react';
import { CenteredLoadingContainer } from './CenteredLoadingElements';
import { colors } from "../../colors";
import { ClimbingBoxLoader } from 'react-spinners';

export default function CenteredLoading() {
    return (
        <CenteredLoadingContainer>
            <ClimbingBoxLoader color={colors.primary} />
        </CenteredLoadingContainer>
    )
}
