import styled from "styled-components";
import { colors } from "../../colors";
import heroBg from "../../images/hero-bg.jpg";

export const HeroContainer = styled.div`
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`

export const HeroBgImage = styled.div`
    background-image: url(${heroBg});
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`

export const HeroOverlay = styled.div`
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(to bottom, ${colors.primaryDarker90} 0%, ${colors.primaryDarker40} 100%);
    padding: 32px;
`

export const HeroTitle = styled.h1`
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-size: 1.5rem;
    margin-top: -15px;
`

export const HeroLogo = styled.img`
    margin: 0 auto;
    display: block;
    margin-top: 100px;
    height: 150px;

    @media screen and (max-width: 960px) {
        margin-top: 50px;
    }
`

export const HeroSubtitle = styled.h2`
    color: #fff;
    font-weight: bolder;
    text-align: center;
    margin-top: 16px;
    font-size: 5rem;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 960px) {
        font-size: 3.5rem;
    }
`

export const HeroDescription = styled.p`
    color: #fff;
    text-align: center;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
    font-size: 1.2rem;
    margin-bottom: 20px;
`

export const AppButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 550px;
    align-content: center;
    margin: 30px auto;
    padding-bottom: 30px;

    @media screen and (max-width: 960px) {
        display: block;
        max-width: 100%;
    }
`

export const AppButton = styled.img`
    width: 200px;
    margin: 10px auto;
    display: block;
`

export const BottomText = styled.p`
    text-align: center;
    color: white;
    max-width: 600px;
    margin: 0 auto;
`